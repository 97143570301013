import * as React from "react"
import PrivacyPolicy from "../components/Sections/PrivacyPolicy"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO 
        lang="ENG" 
        title="Tape Me"
        keywords={['badoo, tinder, TapeMe, love, person, geart, sex, date, Dating, datingsite, findlove, tapeme, voicemessage']}/>
      <PrivacyPolicy/>
    </Layout>
  )
}

export default IndexPage
