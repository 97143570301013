import React, { useState, useEffect } from "react";
import request from "../../utils/request";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    const [processSucceed, setProcessSucceed] = useState(true);
    const [processDone, setProcessDone] = useState(false);
    const [passError, setPassError] = useState({
        message: "",
        show: false
    });
    const [viewModel, setViewModel] = useState({
        password: "",
        passwordAgain: ""
    });

    const changePassword = async () => {
        if (viewModel.password !== "" && viewModel.password === viewModel.passwordAgain && viewModel.password.length > 7) {
            const token = window.location.search.split("=")[1];
            const response = await request.postRequest(`/Auth/resetPassword`, {
                Password: viewModel.password,
                VerificationToken: token
            });
            if (response) {
                setProcessDone(true);
            } else {
                setProcessSucceed(false);
            }
        } else {
            if (viewModel.password !== viewModel.passwordAgain) {
                setPassError({
                    message: "Passwords dont match",
                    show: true
                });
            } else if (viewModel.password.length < 8) {
                setPassError({
                    message: "Password must be longer than 8 letters",
                    show: true
                });
            }
        }
    }

    return (
        <section id="privacy-policy" className="page-section">
            <div className="container position-relative text-center">
                <h1 className="title-verify">Privacy Policy</h1>
                <div className="text-middle">Tape Me Application Privacy Policy</div>
                <div className="text-middle">The application "TapeME" (hereinafter referred to as "the application") is owned and operated by coalsoft s.r.o., a company registered under the laws of the Czech Republic, ID No.: 077 33 259, with registered office at Koliště 1912/13, Černé Pole, 602 00 Brno (hereinafter referred to as "coalsoft", "we" or "our"). Use of the Application and the content and information available on the Application (hereinafter referred to as the "Services") is subject to acceptance of and compliance with this Privacy Policy set out in these Terms of Use (hereinafter referred to as the "Privacy Policy").") and elsewhere on the Application. The terms "you," "your," "user," and "users" refer to all users of the Application. Please read this Privacy Policy carefully. 
    IF YOU DO NOT AGREE TO THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT CONTINUE TO USE THE APP.
    We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will notify you of any changes by updating the "Last Updated" date of this Privacy Policy. We encourage you to check this Privacy Policy periodically to stay informed of updates. If you continue to use the App after the date of posting of this revised Privacy Policy, you will be deemed to have been notified of, and will be subject to and deemed to have accepted, any changes to this revised Privacy Policy.
    This Privacy Policy does not apply to third party online/mobile stores from which you install the App or make payments, including any virtual items in the App that may also collect and use information about you. We are not responsible for any data collected by any such third party.</div>
                <div className="text-middle">1. COLLECTION OF PERSONAL INFORMATION
    We may collect your personal information in a variety of ways. The information we may collect through the App depends on the content and materials you use and includes:
    1.1. Personal Information
    1.1.1 We collect and store any personal information related to your app profile that you voluntarily provide to us when you register or continue to use the app - First and last name - Nickname - Email address - Date of birth - Demographic location - Time zone - Uploaded photos
    1.2 Derived data and uploaded media
    1.2.1 Data is automatically collected by our servers when you use the App, such as your native actions that are integral to the App, actions taken when you create records, edit records and upload media to the App. For this reason, we may also request access to your device's photo or camera to upload your media to the App. All media uploaded in this way will be collected and stored on our servers. If you wish to change our access or permissions, you can do so in your device settings.
    1.3 Facebook permissions
    1.3.1 The App may - if you choose to log in with Facebook - access basic information about your Facebook account, including your name, email, gender, birthday, current city and profile picture URL, as well as other information you choose to disclose. We may also request access to other permissions related to your account, such as friends, check-ins, and likes, and you may choose to grant or deny access to each individual permission.
    1.4 Social media data
    1.4.1 Information about users from social networking sites such as Facebook, including your name, social network username, location, gender, date of birth, email address, profile picture and public contact details if you connect your account to these social networking sites. This information may also include the contact information of anyone you invite to use or join the app.
    1.5 Location Data
    1.5.1 We may ask to access or allow and track location information from your mobile device, either continuously or when you are using the App, to provide location-based services. If you want to change our access or permissions, you can do so in your device settings.
    1.6 Access to your mobile device
    1.6.1 We may ask you to access or enable certain features from your mobile device, including reminders for your mobile device and other features. If you want to change our access or permissions, you can do so in your device settings.
    1.7 Mobile Device Information
    1.7.1 Device information such as mobile device identification number, model and manufacturer, operating system version, phone number, country, location and other data you choose to provide.
    1.8 Push notifications
    1.8.1. We may ask you to send us notifications about your account or app. To opt out of receiving these types of communications, you can turn them off in your device settings.
    1.9 Contest, giveaway and survey data
    1.9.1 Personal and other information you may provide when entering contests or giving away gifts and/or responding to surveys.</div>
                <div className="text-middle">2. USE OF YOUR DATA
    2.1 Accurate information about you enables us to provide you with a seamless, efficient and personalised experience. Specifically, we may use the information collected about you through the App to - Create a personal profile to better personalize future visits to the App. - Monitor and analyze usage and trends to improve your experience with the app - Notify you of app updates. - Request feedback and contact you about your use of the app. - Improving our website and services (we are constantly trying to improve the app and services based on your feedback) - We may periodically send promotional emails about new products, special offers or other information we think may be of interest using the email address you have provided.
    2.2 We will also require you to link your iTunes account, ApplePay account or Google Play account to our app if you wish to purchase any of our advanced services. This does not give us access to your credit card or banking information, but it is necessary for us to process your purchases/subscriptions.
    2.3 All data collected is stored in an anonymised form. If there is no longer any purpose for processing certain data, the data will be deleted.
    3. DISCLOSURE OF YOUR INFORMATION
    3.1 In certain situations we may share information we have collected about you. Your information may be disclosed as follows:
    We will not sell, distribute or rent your personal information to third parties not affiliated with us unless we have your permission or are required by law to do so. We may need to send your personal information to our third-party vendors who have contractual obligations to keep this information confidential. We may also use your personal information to send you promotional information about third parties that we think may be of interest to you, if you tell us that you wish us to do so. Finally, if we ever sell an application or are involved in a merger or consolidation and our user information is one of the assets involved in the sale, your personal information will be part of that sale or transfer.
    3.2 We may also disclose your personal information - To comply with any court order, law or legal process, including in response to any official request, especially if we are asked by government authorities to provide assistance. - To enforce or apply our terms of use and other agreements, including for billing and collection purposes. - When we believe that disclosure is necessary or appropriate to protect the rights, property, or safety of the Application, our customers, or others, such as when we reasonably suspect that a threat to life or limb may exist or that a crime has been committed. This may include exchanging information with other companies and organisations to protect against fraud and reduce credit risk.</div>
                <div className="text-middle">4. MONITORING TECHNOLOGY
    4.1 Cookies and Web Beacons
    4.1.1 We may use cookies, web beacons, tracking pixels and other tracking technologies on the App to help personalize the App and improve your experience. For more information about how we use cookies, please see our Cookie Policy posted on the App, which is incorporated into this Privacy Policy. By using the App, you agree to be bound by our Cookie Policy.
    4.2 Website Analysis 
    4.2.1 We may also partner with select third party vendors such as Google Analytics, Facebook Business Manager to enable tracking technologies and remarketing services on the App using first party and third party cookies to, among other things, analyze and track user usage of the App, determine the popularity of certain content, and better understand online activity. By accessing the app or our website, you consent to the collection and use of your information by these third party vendors. We encourage you to read their privacy policies and contact them directly for answers to your questions. We do not send personal information to these third party vendors. However, if you do not wish to have any information collected and used by tracking technologies, you may visit the third-party vendor or the Network Advertising Initiative's opt-out tool or the Digital Advertising Alliance's opt-out tool.
    4.2.2. You should note that getting a new computer, installing a new browser, upgrading an existing browser or deleting or otherwise changing your browser's cookies may also delete certain cookies, add-ons or logout settings.</div>
                <div className="text-middle">5. THIRD PARTY SITES
    5.1 The App may contain links to third party websites and applications of interest to us, including advertisements and external services that are not affiliated with us. Once you use these links to leave the App, this Privacy Policy does not apply to any information you provide to these third parties and we cannot guarantee the security and privacy of your information. Before visiting and providing any information on any third-party website, you should inquire about the privacy policies and practices (if any) of the third party responsible for that website and should take such steps as you deem necessary to protect the privacy of your information. We are not responsible for the content or the privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Application.
    You should exercise caution and review the privacy statement applicable to the site.</div>
                <div className="text-middle">6. SECURITY OF YOUR INFORMATION
    6.1 We use administrative, technical and physical security measures to protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other misuse. Any information posted online is susceptible to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</div>
                <div className="text-middle">7. CHILDREN UNDER 13
    7.1 Our Service is not intended for children under the age of 13. No one under the age of 13 may provide any personal information within the Application. We do not knowingly collect personal information from children under the age of 13. If you are under 13 years of age, do not use or provide any information within the App or on any of its features/functions, make any purchases through the Website, use any of the interactive or public comment features that may be available within the App, or provide us with any information about you, including your name, address, phone number, email address, or any nickname or username you may use.
    7.2 If we learn that we have collected or received personal information from a child under the age of 13 without verifying parental consent, we will delete that information. If you think we may have any information from or about a child under 13, please contact us at support@tape-me.com.</div>
                <div className="text-middle">8. NON-TRACKING CONTROLS
    8.1 Most web browsers and some mobile operating systems include a Do-Not-Track ('DNT') feature or setting that you can activate to signal your privacy preferences so that your online browsing data is not tracked and collected. No uniform technology standard for the recognition and implementation of DNT signals has been finalized. For this reason, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If an online tracking standard is adopted that we must follow in the future, we will notify you of that process in a revised version of this Privacy Policy.</div>
                <div className="text-middle">10. NOTICE TO USERS IN THE EUROPEAN ECONOMIC AREA
    10.1 If you are visiting and using the Services from the European Economic Area ("EEA"), please be aware that you are sending information (including Personal Information) to the United States where our Partner Platform servers are located. Our partners or employees located outside the EEA may process this information. This information may then be transferred within the United States or back from the United States to other countries outside of your country of residence, depending on the type of information and how we store it. These countries (including the United States) may not necessarily have as comprehensive or protective data protection laws as those in your country of residence; however, our collection, storage and use of your personal information will continue to be governed by this Privacy Policy.
    10.2 BY PROVIDING YOUR PERSONAL INFORMATION TO US, YOU EXPRESSLY CONSENT TO THE TRANSFER OF YOUR PERSONAL INFORMATION FROM EHP AND TO THE PROCESSING OF YOUR INFORMATION IN THE USA, SUBJECT TO THIS PRIVACY POLICY.</div>
                <div className="text-middle">11. YOUR CONSENT
    11.1 By using the App, you agree to our privacy policy.</div>
                <div className="text-middle">12. CONTACT US
    12.1 If you have any problems, you can contact us using the following contacts:
    coalsoft s.r.o.
    Web address: coalsoft.cz
    Address: Koliště 1912/13, Černá Pole, 602 00 Brno, Czech Republic
    Email contact: info@coalsoft.cz</div>
            </div>
        </section>
    )
}

export default PrivacyPolicy